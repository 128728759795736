@import 'src/themes/variables.module';
@import 'src/themes/mixins';

.authentification-page-upper-wrapper {
  display: flex;

  > * {
    flex: 1;
    margin: 50px;
  }

  .register-link{
    margin-top: 16px;
  }
}

.authentification-page-lower-wrapper {
  display: flex;
  justify-content: center;
}

@include mobile-and-tablet {
  .authentification-page-upper-wrapper {
    flex-direction: column;
    margin-bottom: 50px;

    > * {
      margin: 16px;
    }
  }
}

.login-form-header{
  padding-bottom: 22px;
}
